import React, { Component } from "react";

export default class ServerStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ip: "mc.kawamc.com",
      error: null,
      isLoaded: false,
      numbers: {},
      last_7_days: {},
    };
  }

  componentDidMount() {
    fetch("https://stats.kawamc.com/v1/serverOverview?server=1")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            numbers: result.numbers,
            last_7_days: result.last_7_days,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    const { ip, error, isLoaded, numbers, last_7_days } = this.state;
    const style = "helvetica white-80 pt5 ph7 f3 tdark";
    if (error) {
      return (
        <div className={style}>
          IP: <span className="b dim">{ip}</span> | Error:{" "}
          <span className="red b dim">{error.message}</span>
        </div>
      );
    } else if (!isLoaded) {
      return <div className={style}>載入中...</div>;
    } else {
      return (
        <div className={style}>
          <div className="fl w-80 f1">
            IP: <span className="b dim">{ip}</span>
          </div>
          <div className="fl w-20">
            <div className="bb b--blue w-60">
              目前在線:{" "}
              <span className="light-blue b dim">{numbers.online_players}</span>
            </div>
            <div className="bt b--blue w-60">
              平均TPS:{" "}
              <span
                className={
                  last_7_days.average_tps > 18 ? "green b dim" : "red b dim"
                }
              >
                {last_7_days.average_tps}
              </span>
            </div>
          </div>
        </div>
      );
    }
  }
}
